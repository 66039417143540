import ResizeSensor from 'css-element-queries/src/ResizeSensor';

$(document).ready(function() {

	const $window = $(window);
	const banner = $("#banner-wrapper");
	const desktopHeader = $('.desktop-header');
	const mobileHeader = $('.mobile-header');
	const triggerSecondaryOffset = 200; // positive for after, negative for before
	let isShrunk = false;
	let isMobileShrunk = false;
	$(window).on('load scroll touchmove resize', function() {

		const scrolled = $(window).scrollTop();
		let adminBarOffset = 0;

		if ($("body").hasClass('admin-bar')) {
			if ($(window).width() < 768) {
				adminBarOffset = 44;
			} else {
				adminBarOffset = 32;
			}
		}

		$('.mh-sticky').filter(function() {
			const $this = this;
			if (scrolled >= $($this).offset().top - adminBarOffset) {
				$($this).addClass('mh-stuck');
			} else {
				$($this).removeClass('mh-stuck');
			}
			return '';
		});

		if ($("body").hasClass('no-banner')) {
			desktopHeader.removeClass('primary-header').addClass('secondary-header')
			mobileHeader.removeClass('primary-header').addClass('secondary-header');
		} else {
			if (scrolled > triggerSecondaryOffset) {
				if (isShrunk === false) {
					isShrunk = true;
					desktopHeader.removeClass('primary-header').addClass('secondary-header');
				}
				if (isMobileShrunk === false) {
					isMobileShrunk = true;
					mobileHeader.removeClass('primary-header').addClass('secondary-header');
				}
			} else {
				isShrunk = false;
				desktopHeader.removeClass('secondary-header').addClass('primary-header');
				isMobileShrunk = false;
				mobileHeader.removeClass('secondary-header').addClass('primary-header');
			}
		}
	});
});
