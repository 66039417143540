import '../scss/core.scss';

import Cookies from 'js-cookie';
window.Cookies = Cookies;

import LazyLoad from 'vanilla-lazyload';
window.lazyLoadInstance = new LazyLoad({
	elements_selector: ".lazy",
	threshold: 1000
});

import magnificPopup from 'magnific-popup';
window.magnificPopup = magnificPopup;

import slick from 'slick-carousel';
window.slick = slick;

let ww = $(window).width();
$(window).on('load rotate resize', function() {
	ww = $(window).width();
});

// on update of location reload browser
window.locationUpdated = function() {
	location.reload();
};

$(window).on('load', function() {
	$(".defered-styles").attr('rel', 'stylesheet');
});


import './_modernizr';
import MobileDetect from 'mobile-detect';
window.md = new MobileDetect(window.navigator.userAgent);


window.getDeviceType = function getDeviceType() {
    window.deviceTypeClass = (!(!!md.mobile() || !!md.phone() || !!md.tablet()) ? "desktop " : "") + (!!md.mobile() ? "mobile " : "") + (!!md.phone() ? "phone " : "") + (!!md.tablet() ? "tablet " : "");
    let deviceType = false;
    if (!(!!md.mobile() || !!md.phone() || !!md.tablet())) {
        deviceType = 'desktop';
    } else if (!!md.phone()) {
        deviceType = 'mobile';
    } else if (!!md.tablet()) {
        deviceType = 'tablet';
    }
    return deviceType;
}
window.deviceType = getDeviceType();

$(window).on('load resize rotate', function() {

    (!(!!md.mobile() || !!md.phone() || !!md.tablet()) ? "desktop " : "") + (!!md.mobile() ? "mobile " : "") + (!!md.phone() ? "phone " : "") + (!!md.tablet() ? "tablet " : "");
    Modernizr.addTest({
        desktop: !(!!md.mobile() || !!md.phone() || !!md.tablet()),
        mobile: !!md.mobile(),
        phone: !!md.phone(),
        tablet: !!md.tablet()
    });
    if ($(".sp-block").length > 0) {
        $.each($(".sp-block > div"), function() {
            $(this).addClass(deviceTypeClass);
        })
    }
});

function css_browser_selector(u) {
	let ua = u.toLowerCase(),
		is = function(t) {
			return ua.indexOf(t) > -1
		}, g = 'gecko',
		w = 'webkit',
		s = 'safari',
		o = 'opera',
		m = 'mobile',
		h = document.documentElement,
		b = [(!(/opera|webtv/i.test(ua)) && /msie\s(\d)/.test(ua)) ? ('ie ie' + RegExp.$1) : is('firefox/2') ? g + ' ff2' : is('firefox/3.5') ? g + ' ff3 ff3_5' : is('firefox/3.6') ? g + ' ff3 ff3_6' : is('firefox/3') ? g + ' ff3' : is('gecko/') ? g : is('opera') ? o + (/version\/(\d+)/.test(ua) ? ' ' + o + RegExp.$1 : (/opera(\s|\/)(\d+)/.test(ua) ? ' ' + o + RegExp.$2 : '')) : is('konqueror') ? 'konqueror' : is('blackberry') ? m + ' blackberry' : is('android') ? m + ' android' : is('chrome') ? w + ' chrome' : is('iron') ? w + ' iron' : is('applewebkit/') ? w + ' ' + s + (/version\/(\d+)/.test(ua) ? ' ' + s + RegExp.$1 : '') : is('mozilla/') ? g : '', is('j2me') ? m + ' j2me' : is('iphone') ? m + ' iphone' : is('ipod') ? m + ' ipod' : is('ipad') ? m + ' ipad' : is('mac') ? 'mac' : is('darwin') ? 'mac' : is('webtv') ? 'webtv' : is('win') ? 'win' + (is('windows nt 6.0') ? ' vista' : '') : is('freebsd') ? 'freebsd' : (is('x11') || is('linux')) ? 'linux' : '', 'js'];
	const c = b.join(' ');
	h.className += ' ' + c;
	var ie = ua.search(/(MSIE|Trident)/);
	if (ie > -1) {
		$("html").addClass('browse-happy');
	}
	$("html").addClass(c);
	return c;
}
const browser = css_browser_selector(navigator.userAgent);

import AOS from "aos";
window.AOS = AOS;
$(document).ready(function() {
	if ($("*[data-aos]").length) {
		AOS.init({
			easing: 'ease-in-out-back',
			duration: 1000,
			offset: 100,
			delay: 0
		});
	}
})

import notify from 'notifyjs-browser';

// Gravity Form Javascript
import './_forms';

// Auto import js from component folder
import './../../library/components/bannerHome/bannerHome.js'; import './../../library/components/button/button.js'; import './../../library/components/informationBar/informationBar.js'; import './../../library/components/pageIntroComponent/pageIntroComponent.js';
// Auto import js from blocks folder
import './../../library/blocks/formCTA/formCTA.js'; import './../../library/blocks/hoverBoxes/hoverBoxes.js'; import './../../library/blocks/sidebar/sidebar.js';
// Auto import js from widgets folder
import './../../library/widgets/gravityForm/gravityForm.js'; import './../../library/widgets/guideWidget/guideWidget.js'; import './../../library/widgets/navigationMenu/navigationMenu.js';
// Auto import js from cpt folder
import './../../library/cpt/guide/guide.js'; import './../../library/cpt/videoHub/videoHub.js';
// Auto import js from templates folder
import './../../library/templates/breadcrumbs/breadcrumbs.js'; import './../../library/templates/client/contactUs/contactAccordion/contactAccordion.js'; import './../../library/templates/client/location/googleMap/googleMap.js'; import './../../library/templates/client/location/map/map.js'; import './../../library/templates/content/blog/blogPost/blogPost.js'; import './../../library/templates/footer/footer.js'; import './../../library/templates/form/form.js'; import './../../library/templates/guide/guide.js'; import './../../library/templates/header/desktopHeader/desktopHeader.js'; import './../../library/templates/header/header.js'; import './../../library/templates/header/mobileHeader/mobileHeader.js'; import './../../library/templates/icon/icon.js'; import './../../library/templates/menu/desktopMenu/desktopMenu.js'; import './../../library/templates/menu/mobileMenu/mobileMenu.js'; import './../../library/templates/scrollSnap/scrollSnap.js'; import './../../library/templates/share/share.js'; import './../../library/templates/titleSeo/titleSeo.js';

window.matchHeight = function(element) {
	$(window).on('load rotate resize', function() {
		if ($(window).width() > 767) {
			let maxHeight = 0;
			let match;
			match = $(element);
			match.height('auto');
			match.each(function() {
				if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
			});
			match.height(maxHeight);
		}
	});
}

// Get the users coordinates
window.getLocation = function(callback) {
	if (coreData.spOptions.location_update !== 'disabled') {
		if ("geolocation" in navigator) {
			function position(position) {
				const userCoords = {
					latitude: position.coords.latitude,
					longitude: position.coords.longitude
				};
				if (typeof callback !== 'undefined') {
					callback(userCoords);
				}
			}
			function error(error) {
				console.log(error);
			}
			const options = {}
			if (coreData.spOptions.location_update == 'change') {
				navigator.geolocation.watchPosition(position, error, options);
			} else if (coreData.spOptions.location_update == 'reload') {
				navigator.geolocation.getCurrentPosition(position, error, options);
			}
		}
	}
};

window.setLocation = function(location, callback) {
	// if the client data has more than 1 location
	if (Object.keys(coreData.clientData).length > 1) {
		// initiate the getLocation function
		getLocation(function(userCoords) {
			const currentLocation = Cookies.get('closest_location');
			$.post(coreData.ajaxurl, {
				action: 'findClosestLocation',
				location: location,
				userCoords: userCoords
			}, function(response) {
				Cookies.set('closest_location', response);
				// No we need to reinitiate all the loadable elements
				response = JSON.parse(response);
				const locationID = response.id;
				let clientData = {};
				if (locationID) {
					clientData = coreData.clientData[locationID];
				}

				// Check to see if the current location is the same
				// as the new location.
				let updateLocation = true;
				if (typeof currentLocation !== "undefined") {
					const location = JSON.parse(currentLocation);
					if (location.id === locationID) {
						updateLocation = false;
					}
				}

				// only update the site if the location has changed
				if (updateLocation) {
					// Only get the templates that have the dynamic template class
					const templates = $(".sp-template[data-load='dynamic']");
					$.each(templates, function(key, template) {
						const templateSlug = $(template).data('template');
						let data = {};
						if ($(template).length) {
							if ($(template).hasClass('merge-tag')) {
								data.is_merge_tag = true;
							}
							$.post(coreData.ajaxurl, {
								action: 'loadTemplate',
								template: templateSlug,
								data: data
							}, function(data) {
								$(template).replaceWith(data);
							});
						}
					});
				}
				if (typeof callback !== 'undefined') {
					callback();
				}
			})
		});
	}
}

// Read a page's GET URL variables and return them as an associative array.
window.getUrlVars = function() {
	let vars = {};
	let hash;
	const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for (let i = 0; i < hashes.length; i++) {
		hash = hashes[i].split('=');
		if (typeof hash[1] !== 'undefined') {
			vars[hash[0]] = hash[1];
		}
	}
	return vars;
}

window.getUrlParameter = function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
};

window.popupCenter = function(url, title, w, h) {
	const y = window.outerHeight / 2 + window.screenY - ( h / 2)
	const x = window.outerWidth / 2 + window.screenX - ( w / 2)
	return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
}

window.formPopup = function formPopup(data) {
	const form = typeof data.form !== "undefined" ? data.form : false;
	const title = typeof data.title !== "undefined" ? data.title : false;
	const content = typeof data.content !== "undefined" ? data.content : false;
	if (form) {
		$.magnificPopup.close();
		$.magnificPopup.open({
			type: 'ajax',
			items: {},
			mainClass: 'form-popup fade-in',
			tLoading: coreData.loader,
			ajax: {
				settings: {
					type: "post",
					url: coreData.ajaxurl,
					data: {
						action: "formPopup",
						form: form,
						title: title,
						content: content
					},
				},
				cursor: 'mfp-ajax-cur',
				tError: '<a href="%url%">The content</a> could not be loaded.'
			},
			callbacks: {
				ajaxContentAdded: function() {

				}
			}
		});
	}
}
