import mhead from 'mhead-js';
$(window).on('load', function() {
	const desktopHeader = $('.desktop-header');
	const banner = $("#banner-wrapper");
	desktopHeader.mhead({
		scroll: {
			hide: 100,
			show: 100,
			tolerance: 1
		}
	});
});
