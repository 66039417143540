/* Banner Home Component JS File */
import slick from 'slick-carousel';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';
import TimelineMax from 'TimelineMax';
import Parallax from 'parallax-js';

$(document).ready(function() {
	if ($(".banner-home-wrapper").length) {

		$(".banner-home-slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 2000,
			mobileFirst: true,
			pauseOnDotsHover: false,
			pauseOnFocus: false,
			pauseOnHover: true,
			arrows: false,
			dots: true,
			fade: true
		});

		const bannerHomeController = new ScrollMagic.Controller();
		const bannerHomeScene = new ScrollMagic.Scene({
			triggerHook: "onEnter",
			duration: "200%"
		})
		.setTween(".banner-home-wrapper .banner-background .background", {
			y: "40%"
		});
		bannerHomeScene.addTo(bannerHomeController);

		$(document).on('click', '#scroll-to-content', function(event) {
			// our-mission
			let offset = 200;
			TweenMax.to(window, 1.2, {
				scrollTo: {
					y: $("#content-wrapper").offset().top - offset,
					autoKill: false
				},
				ease: Power4.easeInOut
			});
		});
	}

	const scene = document.getElementById('parallax-images');
	const parallaxInstance = new Parallax(scene, {
		relativeInput: false
	});

});

