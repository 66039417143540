$(document).ready(function() {
	if ($('.form-template').length) {
		if ($('.form-template').parents('.sticky-form-wrapper').length) {
			if ($(window).width() >= 768) {
				const options = {
					parent: '.sticky-form-wrapper',
					inner_scrolling: true,
					offset_top: 220
				};
				$(".form-template").stick_in_parent(options);
				new ResizeSensor($('.sticky-form-wrapper'), function() {
					$(".form-template").trigger("sticky_kit:recalc");
				});
			}
		}
	}
});
